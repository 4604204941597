import React from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ProductCartContextProvider from "contaxt/CartProductContext";
import Cookies from 'js-cookie';

function App() {

  return (
    <HelmetProvider>
      <Helmet>
        <title> Menus</title>
        <meta
          name="description"
          content="Basil Menus"
        />
      </Helmet>
      <ProductCartContextProvider>
      {/* MAIN APP */}
        <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
          <MyRouter />
        </div>
      </ProductCartContextProvider>

    </HelmetProvider>
  );
}

export default App;
