import axios, { AxiosRequestConfig } from "axios";
import appConfig from "configs/app.config";
import deepParseJson from "utils/deepParseJson";
import Cookies from 'js-cookie';
const unauthorizedCode = [401];
const PERSIST_STORE_NAME = "admin";
const MERCHANT_STORAGE_KEY = "merchant";
const authToken =
  "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxLENoYWRuaSxyb3ksTW9vcmUsMTIzMTIzMTIzNCx0ZXN0QHBvc2Jhc2lsLmNvbSxCQVNJTDAwNzY3MyIsImlzcyI6IkJhc2lsLVBvcyIsImlhdCI6MTcwNzE0MTczMywiZXhwIjoxNzA3MjI4MTMzfQ.UcAay3lQAApA5_i2CZuf7uGzHSY6FC5s_qFxvz92ImfoVy0Xg38ed1P9YKqP8jBOE9rcKs-NSc7AiOym302wMA";

const BaseService = axios.create({
  timeout: 60000,
  baseURL: appConfig.apiPrefix,
});
const saveMerchantToLocalStorage = (merchant: any) => {
  localStorage.setItem(MERCHANT_STORAGE_KEY, merchant);
};

const extractMerchantFromURL = () => {
  const pathArray = window.location.pathname.split("/");
  return pathArray[1] || null;
};

const getHeaders = (): AxiosRequestConfig["headers"] => {
  const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
  const persistData = deepParseJson(rawPersistData);

  const timeZone = "America/Chicago";
  const merchant = localStorage.getItem(MERCHANT_STORAGE_KEY);
  const merchantMID = Cookies.get('merchant');
  const headers: AxiosRequestConfig["headers"] = {
    // MID: merchant,
    MID: `${merchantMID}`,
    "Content-Type": "application/json",
    TimeZone: timeZone,
    Accept: "*/*",
    "Cache-Control": "no-cache",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",

  };

  // Check if authToken is defined before adding Authorization header
  // if (authToken) {
  //   headers.Authorization = `Bearer ${authToken}`;
  // }

  return headers;
};

BaseService.interceptors.request.use(
  (config: any) => {
    const headers: AxiosRequestConfig["headers"] = getHeaders();
    // Assign the headers to the config object
    config.headers = headers;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default BaseService;
