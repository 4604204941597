import { NoSymbolIcon, CheckIcon } from "@heroicons/react/24/outline";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { useCartDataProvider } from "contaxt/CartProductContext";
import { Product } from "data/data";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";

const CartPage = () => {
  const { Products, dispatch }: any = useCartDataProvider();
  const navigate = useNavigate();

  const renderStatusSoldout = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
        <NoSymbolIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none">Sold Out</span>
      </div>
    );
  };

  const renderStatusInstock = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
        <CheckIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none">In Stock</span>
      </div>
    );
  };

  const calculatingTotalPrice = (item: Product) => {
    const { price, quantity, modifierChecked, modifierSets } = item;
    let basePrice = quantity ? price * quantity : price;
    if (modifierSets) {
      modifierSets.forEach((modifierSets) => {
        const matchingKey =
          modifierChecked &&
          Object.keys(modifierChecked)?.find(
            (key) => Number(key) === modifierSets.id
          );
        if (matchingKey) {
          modifierSets.modifierSetModifierItems?.forEach((modifierItem) => {
            const modifierItemId = modifierItem.id.toString();
            if (modifierChecked[matchingKey]?.[modifierItemId] !== undefined) {
              basePrice += modifierChecked[matchingKey][modifierItemId]
                ? modifierItem.modifierItem.price
                : 0;
            }
          });
        }
      });
    }
    return basePrice;
  };

  const allProductPrice = Products.reduce(
    (accumulator: number, currentValue: Product) =>
      accumulator + calculatingTotalPrice(currentValue),
    0
  ).toFixed(2);

  const renderProduct = (item: Product, index: number) => {
    const {
      imageUrl,
      price,
      name,
      id,
      isAvailable,
      quantity,
      modifierChecked,
      modifierSets,
    } = item;

    return (
      <div
        key={index}
        className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
      >
        <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={imageUrl}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
          <button
            onClick={() =>
              navigate(`/product-detail/${id}`, { state: modifierChecked })
            }
            className="absolute inset-0"
          ></button>
        </div>
        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">{name}</h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                  <div className="flex items-center space-x-1.5"></div>
                  <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                  <div className="flex items-center space-x-1.5"></div>
                </div>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <select
                    name="qty"
                    id="qty"
                    className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={quantity ? price * quantity : price}
                  />
                </div>
              </div>

              <div className="hidden sm:block text-center relative">
                <NcInputNumber
                  className="relative z-10"
                  quantity={quantity}
                  id={id}
                />
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices
                  price={calculatingTotalPrice(item)}
                  className="mt-0.5"
                />
              </div>
            </div>
          </div>

          {modifierSets?.map((item) => {
            const matchingKey =
              modifierChecked &&
              Object.keys(modifierChecked)?.find(
                (key) => Number(key) === item.id
              );
            if (matchingKey) {
              let totalPrice = 0;
              item.modifierSetModifierItems?.forEach((modifierItem) => {
                const modifierItemId = modifierItem.id.toString();
                if (modifierChecked[matchingKey]?.[modifierItemId]) {
                  totalPrice += modifierItem.modifierItem.price;
                }
              });
              return (
                <div key={item.id} className="flex flex-col">
                  {item.modifierSetModifierItems?.some((modifierItem) =>
                    Object.entries(modifierChecked[matchingKey]).some(
                      ([key, value]) =>
                        value === true && modifierItem.id === Number(key)
                    )
                  ) && (
                    <h1 className="text-lg font-bold">
                      {item.name}:- ${totalPrice}
                    </h1>
                  )}
                  <div className="flex flex-wrap">
                    {item.modifierSetModifierItems?.map((modifierItem) => {
                      return (
                        <React.Fragment key={modifierItem.id}>
                          {Object.entries(modifierChecked[matchingKey])
                            .filter(([_, value]) => value === true)
                            .map(([innerKey]) => {
                              if (modifierItem.id === Number(innerKey)) {
                                return (
                                  <h1 key={innerKey} className="text-base mr-4">
                                    {modifierItem.modifierItem.name}
                                  </h1>
                                );
                              }
                              return null;
                            })}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              );
            }
            return null;
          })}

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            {isAvailable ? renderStatusInstock() : renderStatusSoldout()}

            <a
              href="##"
              className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm"
              onClick={() => dispatch({ type: "REMOVE_ITEMS", payload: id })}
            >
              <span>Remove</span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-CartPage">
      {/* <Helmet>
        <title>Shopping Cart || Ciseco Ecommerce Template</title>
      </Helmet> */}

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-12 sm:mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Shopping Cart
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/#"} className="">
              Homepage
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <Link to={"/#"} className="">
              Clothing Categories
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Shopping Cart</span>
          </div>
        </div>

        <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />

        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-[60%] xl:w-[55%] divide-y divide-slate-200 dark:divide-slate-700 ">
            {Products.map(renderProduct)}
          </div>
          <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div>
          <div className="flex-1">
            <div className="sticky top-28">
              <h3 className="text-lg font-semibold ">Order Summary</h3>
              <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                <div className="flex justify-between pb-4">
                  <span>Subtotal</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    ${allProductPrice}
                  </span>
                </div>
                <div className="flex justify-between py-4">
                  <span>Shpping estimate</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    $5.00
                  </span>
                </div>
                <div className="flex justify-between py-4">
                  <span>Tax estimate</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    $24.90
                  </span>
                </div>
                <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                  <span>Order total</span>
                  <span>${allProductPrice}</span>
                </div>
              </div>
              <ButtonPrimary href="/checkout" className="mt-8 w-full">
                Checkout
              </ButtonPrimary>
              <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
                <p className="block relative pl-5">
                  <svg
                    className="w-4 h-4 absolute -left-1 top-0.5"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 8V13"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.9945 16H12.0035"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Learn more{` `}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="##"
                    className="text-slate-900 dark:text-slate-200 underline font-medium"
                  >
                    Taxes
                  </a>
                  <span>
                    {` `}and{` `}
                  </span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="##"
                    className="text-slate-900 dark:text-slate-200 underline font-medium"
                  >
                    Shipping
                  </a>
                  {` `} infomation
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CartPage;
