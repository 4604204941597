import React, {
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import CartReducer from "./CartReducer";
import { Product } from "data/data";

type ReducerAction = {
  type: string;
  payload: any;
};

interface InitialState {
  Products: [];
  state: Product[];
  dispatch: Dispatch<ReducerAction>;
  total_price: any;
}

const Cartcontext = createContext<InitialState>({
  Products: [],
  dispatch: () => undefined,
  state: [],
  total_price: "",
});

const initialValue = {
  Products: [],
  total_price: "",
};

const ProductCartContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(CartReducer, initialValue);

  const value = {
    ...state,
    dispatch,
  };

  
  return <Cartcontext.Provider value={value}>{children}</Cartcontext.Provider>;
};

export const useCartDataProvider = () => {
  return useContext(Cartcontext);
};

export default ProductCartContextProvider;
