import { FC, useEffect, useState } from "react";
import HeaderFilterSection from "components/HeaderFilterSection";
import ProductCard from "components/ProductCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CategoryData, Product } from "data/data";
import { apiGetItemData } from "services/MenuService";
import { getAllCategoryNameData } from "services/HomeService";

export interface PageHomeMenuProps {
  data?: Product[];
}

const PageHomeMenu: FC<PageHomeMenuProps> = () => {
  const [itemData, setItemData] = useState<Product[]>([]);
  const [displayedItems, setDisplayedItems] = useState<number>(10);
  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);

  const getItemData = async () => {
    try {
      const res = await apiGetItemData();
      setItemData(res.data as Product[])
    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getItemData()
  }, []);

  const renderAllData = () => {
    getItemData()
  }

  const getAllCategoryName = async () => {
    try {
      const allCategoryRes = await getAllCategoryNameData();
      setCategoryData(allCategoryRes.data as CategoryData[])
    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllCategoryName()
  }, [])

  const renderMoreData = () => {
    setDisplayedItems(itemData.length);
  };
  const renderLessData = () => {
    setDisplayedItems(10);
  };
  const readytoDataLess = displayedItems === itemData.length

  return (
    <div className="nc-PageHomeMenu relative">
      <HeaderFilterSection itemData={itemData} getItemData={getItemData} setItemData={setItemData} renderAllData={renderAllData} categoryData={categoryData} />
      <div className={`grid gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `} >
        {itemData.slice(0, displayedItems).map((item: Product, index: number) => (
          <ProductCard categoryData={categoryData} data={item} key={index} />
        ))}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary onClick={() => (readytoDataLess ? renderLessData() : renderMoreData())}>
          {`${readytoDataLess ? 'Show less' : 'Show me more'}`}
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default PageHomeMenu;

