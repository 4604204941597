const CartReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD_TO_CART":
      const existingItems = state.Products.find(
        (item: any) => item.id === action.payload.id
      );

      if (existingItems) {
        const updatedProductsData = state.Products.map((item: any) => {
          if (item.id === action.payload.id) {
            const Updatedquantity = item.quantity + 1;
            return {
              ...item,
              quantity: Updatedquantity,
              modifierChecked: {...item.modifierChecked,...action.payload.modifierChecked,}
            };
          } else {
            return item;
          }
        });
        const newStateWithUpdatedProducts = {
          ...state,
          Products: updatedProductsData,
        };
        return newStateWithUpdatedProducts;
      }
      const qunty = action.payload.quantity ? action.payload.quantity : 1;
      const SingleProductData = { ...action.payload, quantity: qunty };
      const updatedProducts = [...state.Products, SingleProductData];
      return {
        ...state,
        Products: updatedProducts,
      };

    case "REMOVE_ITEMS":
      const filterProduct = state.Products.filter((items: any) => {
        return items.id !== action.payload;
      });
      return {
        ...state,
        Products: filterProduct,
      };

    case "HANDLE_DECREMENT":
      let updateData = state.Products.map((item: any) => {
        if (item.id === action.payload) {
          let value = item.quantity - 1;
          if (value < 1) {
            value = 1;
          }
          return { ...item, quantity: value };
        } else {
          return item;
        }
      });
      return {
        ...state,
        Products: updateData,
      };
    case "HANDLE_INCREMENT":
      let updatedDataInc = state.Products.map((item: any) => {
        if (item.id === action.payload) {
          let value = item.quantity + 1;
          return { ...item, quantity: value };
        } else {
          return item;
        }
      });
      return {
        ...state,
        Products: updatedDataInc,
      };
    case "TOTAL_PRICE":
      let totalprice = state.Products.reduce((accum: any, ele: any) => {
        return accum + ele.quantity * ele.price;
      }, 0);
      return {
        ...state,
        total_price: totalprice,
      };

    default:
      return { ...state };
  }
};

export default CartReducer;
