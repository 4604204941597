import ApiService from "./ApiService";

export async function getAllCategoryNameData<T>() {
  return ApiService.fetchData<T>({
    url: `/category`,
    method: "get",
  });
}

export async function getDataByCategoryId<T>(id: number) {
  return ApiService.fetchData<T>({
    url: `/item-category/item-by-category/${id}`,
    method: "get",
  });
}
